import React, { Fragment, memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import Safe from 'react-safe'
import isStolApp, { DEVICES } from '../../../../modules/is-stolapp'
import useScript from 'react-script-hook'
import addJokerCardCallback from './modules/add-joker-card-callback'
import isServerSide from '../../../../modules/is-server-side'
import consoleLogger from '../../../../modules/console-logger'
import { useCMPContext } from '../../../Sourcepoint/components/CMPProvider'
import handlerWindowDlab from '../../../../bundles/BundleAds/modules/handler-window-dlab'

const Wrapper = styled.div`
  @media print {
    display: none;
  }
`

const Outbrain = ({ location, ...props }) => {
  var { dlabOption } = handlerWindowDlab('vendor', { sub: 'Outbrain' })
  const [consoleLog] = consoleLogger('Outbrain')
  const [ready, setReady] = useState(false)
  const [loading, error] = useScript({
    src: ready ? '//widgets.outbrain.com/outbrain.js' : null,
    checkForExisting: true,
  })

  useEffect(() => {
    addJokerCardCallback()
    dlabOption.init = () => {
      consoleLog('consent', 'given')
      setReady(true)
    }
    if ((dlabOption.consent = true)) {
      setReady(true)
    }
  }, [])
  useEffect(() => {
    consoleLog('window var OBR', window.OBR)
    if (!loading && ready && window.OBR) {
      window.OBR.extern.reloadWidget()
      consoleLog('reload widget', 'success')
    }
  }, [loading, ready])

  consoleLog('loading', loading)
  consoleLog('error', error)
  consoleLog('consent ready', ready)

  if (loading || error) {
    return false
  }

  let appOptions = {}

  if (isStolApp() && !isServerSide()) {
    appOptions['data-ob-user-id'] = window.device_native_advertising_identifier || null
    appOptions['data-app-ver'] = window.athesia_react && window.athesia_react.app_ver
  }

  if (isStolApp(DEVICES.android)) {
    appOptions['data-widget-id'] = 'MB_22'
    appOptions['data-ob-installation-key'] = 'DESTO3A1DOME5GMM4PG68KEDO'
  }
  if (isStolApp(DEVICES.ios)) {
    appOptions['data-widget-id'] = 'MB_11'
    appOptions['data-ob-installation-key'] = 'DESTO1FIQDBB6C036G2A5N3QA'
  }
  let base = ''
  let pathname = ''
  if (!isServerSide()) {
    base = location && location.base ? location.base : window.location.origin
    pathname = location && location.pathname ? location.pathname : window.location.pathname
  }

  consoleLog('app options', appOptions)
  consoleLog('base', base)
  consoleLog('pathname', pathname)
  return (
    <Wrapper {...props}>
      {!isServerSide() && (
        <Fragment>
          <Safe.div className="OUTBRAIN" data-src={base + pathname} data-widget-id="AR_1" {...appOptions} />
        </Fragment>
      )}
    </Wrapper>
  )
}

export default memo(Outbrain)
