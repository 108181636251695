import Outbrain from '../../../../../../vendor/AdThirdParty/components/Outbrain'
import React from 'react'
import abTestResult from '../../../../../../bundles/BundleAds/modules/ab-test'
import pushEventToDataLayer from '../../../../../../modules/push-event-to-data-layer'
import Taboola from '../../../../../../vendor/AdThirdParty/components/Taboola'

const ReadingRecommendation = ({ location }) => {
  return <Outbrain location={location} />
}

export default ReadingRecommendation
